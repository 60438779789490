import { Navigate, useRoutes, Outlet } from "react-router-dom";
import { lazy, Suspense } from "react";
import GuestGuard from "src/auth/guard/GuestGuard";
import SplashScreen from "src/components/SplashScreen";
import AuthLayout from "src/layouts/AuthLayout";
import AuthGuard from "src/auth/guard/AuthGuard";
import LoadingScreen from "src/components/LoadingScreen";
import DashboardLayout from "src/layouts/DashboardLayout/DashboardLayout";
import MyPageLayout from "src/layouts/MyPageLayout/MyPageLayout";
import VideoChoosePreviewPage from "src/pages/dashboard/videoChoosePreviewPage/VideoChoosePreviewPage";
import EmailVerifyGuard from "src/auth/guard/EmailVerifyGuard";
import PaidGuard from "src/auth/guard/PaidGuard";
import TermsOfServices from "src/pages/legal/TermsOfServices";
import { useAuthData } from "src/auth/firebase/AuthProvider";

const Login = lazy(() => import("src/pages/auth/Login"));
const Register = lazy(() => import("src/pages/auth/Register"));
const PasswordReset = lazy(() => import("src/pages/auth/PasswordReset"));
const SendEmailVerification = lazy(
  () => import("src/pages/auth/SendEmailVerification")
);
const OverviewAppPage = lazy(
  () => import("src/pages/dashboard/overviewPage/OverviewPage")
);
const VideoListPage = lazy(
  () => import("src/pages/dashboard/videoListPage/VideoListPage")
);
const VideoChooseEditPage = lazy(
  () => import("src/pages/dashboard/videoChooseEditPage/VideoChooseEditPage")
);

const VideoSubtitleEditPage = lazy(
  () =>
    import("src/pages/dashboard/videoChooseEditSubtitle/VideoSubtitleEditPage")
);

const VideoAnnotationEditPage = lazy(
  () =>
    import(
      "src/pages/dashboard/videoChooseEditAnnotation/VideoAnnotationEditPage"
    )
);

const AnalyticsListPage = lazy(
  () => import("src/pages/dashboard/analyticsListPage/AnalyticsListPage")
);
const AnalyticsPage = lazy(
  () => import("src/pages/dashboard/analyticsPage/AnalyticsPage")
);

const FormDataListPage = lazy(
  () => import("src/pages/dashboard/formDataListPage/FormDataListPage")
);

const FormDataPage = lazy(
  () => import("src/pages/dashboard/formDataPage/FormDataPage")
);

const SettingsPage = lazy(
  () => import("src/pages/dashboard/settingsPage/SettingsPage")
);

const PlanManagementPage = lazy(
  () => import("src/pages/mypage/planManagementPage/PlanManagementPage")
);
const UserManagementPage = lazy(
  () => import("src/pages/mypage/userManagementPage/UserManagementPage")
);

const MyPage = lazy(() => import("src/pages/mypage/myPage/MyPage"));

export default function Router() {
  const { user } = useAuthData();

  return useRoutes(
    user?.dashboard_type === "only-analytics"
      ? [
          {
            path: "/",
            element: <Navigate to="/dashboard/analytics" replace />,
          },
          {
            path: "dashboard",
            element: (
              <AuthGuard>
                {/* <PaidGuard> */}
                <DashboardLayout>
                  <Suspense fallback={<LoadingScreen />}>
                    <Outlet />
                  </Suspense>
                </DashboardLayout>
                {/* </PaidGuard> */}
              </AuthGuard>
            ),
            children: [
              {
                element: <Navigate to="/dashboard/analytics" replace />,
                index: true,
              },
              { path: "analytics", element: <AnalyticsListPage /> },
              { path: "analytics/:videoChooseId", element: <AnalyticsPage /> },
            ],
          },

          {
            path: "auth",
            element: (
              <GuestGuard>
                <Suspense fallback={<SplashScreen />}>
                  <Outlet />
                </Suspense>
              </GuestGuard>
            ),
            children: [
              {
                path: "login",
                element: (
                  <AuthLayout>
                    <Login />
                  </AuthLayout>
                ),
              },
              {
                path: "register",
                element: (
                  <AuthLayout>
                    {user?.role === "admin" ? (
                      <Register />
                    ) : (
                      <Navigate to="https://www.video-choose.com/register/" />
                    )}
                  </AuthLayout>
                ),
              },
              {
                path: "password-reset",
                element: (
                  <AuthLayout>
                    <PasswordReset />
                  </AuthLayout>
                ),
              },
            ],
          },
          {
            path: "email-send",
            element: (
              <EmailVerifyGuard>
                <Suspense fallback={<SplashScreen />}>
                  <Outlet />
                </Suspense>
              </EmailVerifyGuard>
            ),
            children: [
              {
                path: "verification",
                element: (
                  <AuthLayout>
                    <SendEmailVerification />
                  </AuthLayout>
                ),
              },
            ],
          },
          {
            path: "legal",
            element: (
              <Suspense fallback={<SplashScreen />}>
                <Outlet />
              </Suspense>
            ),
            children: [
              {
                path: "terms-of-service",
                element: <TermsOfServices />,
              },
            ],
          },
          { path: "*", element: <Navigate to="/404" replace /> },
        ]
      : [
          {
            path: "/",
            element: <Navigate to="/dashboard" replace />,
          },
          {
            path: "dashboard",
            element: (
              <AuthGuard>
                {/* <PaidGuard> */}
                <DashboardLayout>
                  <Suspense fallback={<LoadingScreen />}>
                    <Outlet />
                  </Suspense>
                </DashboardLayout>
                {/* </PaidGuard> */}
              </AuthGuard>
            ),
            children: [
              // { element: <OverviewAppPage />, index: true },
              { element: <VideoListPage />, index: true },
              // { path: "video-list", element: <VideoListPage /> },
              {
                path: "video-list/:videoChooseId/edit",
                element: <VideoChooseEditPage />,
              },
              { path: "analytics", element: <AnalyticsListPage /> },
              { path: "analytics/:videoChooseId", element: <AnalyticsPage /> },
              { path: "form-data", element: <FormDataListPage /> },
              { path: "form-data/:videoChooseId", element: <FormDataPage /> },
              { path: "settings", element: <SettingsPage /> },
            ],
          },

          {
            path: "dashboard",
            element: (
              <AuthGuard>
                <Suspense fallback={<LoadingScreen />}>
                  <Outlet />
                </Suspense>
              </AuthGuard>
            ),
            children: [
              {
                path: "video-list/:videoChooseId/edit/subtitle/:videoId",
                element: <VideoSubtitleEditPage />,
              },
              {
                path: "video-list/:videoChooseId/edit/annotation/:videoId",
                element: <VideoAnnotationEditPage />,
              },
            ],
          },
          {
            path: "dashboard/video-list/:videoChooseId/preview",
            element: (
              <AuthGuard>
                <VideoChoosePreviewPage />
              </AuthGuard>
            ),
          },
          // {
          //   path: "mypage",
          //   element: (
          //     <AuthGuard>
          //       <MyPageLayout>
          //         <Suspense fallback={<LoadingScreen />}>
          //           <Outlet />
          //         </Suspense>
          //       </MyPageLayout>
          //     </AuthGuard>
          //   ),
          //   children: [
          //     { element: <MyPage />, index: true },
          //     {
          //       path: "plan-management",
          //       element: <PlanManagementPage />,
          //     },
          //     {
          //       path: "user-management",
          //       element: <UserManagementPage />,
          //     },
          //   ],
          // },
          {
            path: "auth",
            element: (
              <GuestGuard>
                <Suspense fallback={<SplashScreen />}>
                  <Outlet />
                </Suspense>
              </GuestGuard>
            ),
            children: [
              {
                path: "login",
                element: (
                  <AuthLayout>
                    <Login />
                  </AuthLayout>
                ),
              },
              {
                path: "register",
                element: (
                  <AuthLayout>
                    {user?.role === "admin" ? (
                      <Register />
                    ) : (
                      <Navigate to="https://www.video-choose.com/register/" />
                    )}
                  </AuthLayout>
                ),
              },
              {
                path: "password-reset",
                element: (
                  <AuthLayout>
                    <PasswordReset />
                  </AuthLayout>
                ),
              },
            ],
          },
          {
            path: "email-send",
            element: (
              <EmailVerifyGuard>
                <Suspense fallback={<SplashScreen />}>
                  <Outlet />
                </Suspense>
              </EmailVerifyGuard>
            ),
            children: [
              {
                path: "verification",
                element: (
                  <AuthLayout>
                    <SendEmailVerification />
                  </AuthLayout>
                ),
              },
            ],
          },
          {
            path: "legal",
            element: (
              <Suspense fallback={<SplashScreen />}>
                <Outlet />
              </Suspense>
            ),
            children: [
              {
                path: "terms-of-service",
                element: <TermsOfServices />,
              },
            ],
          },
          { path: "*", element: <Navigate to="/404" replace /> },
        ]
  );
}
