const ROOTS = {
  AUTH: "/auth",
  AUTH_DEMO: "/auth-demo",
  EMAIL_SEND: "/email-send",
  DASHBOARD: "/dashboard",
  MYPAGE: "/mypage",
  LEGAL: "/legal",
};

export const paths = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  auth: {
    login: `${ROOTS.AUTH}/login`,
    register: `${ROOTS.AUTH}/register`,
    passwordReset: `${ROOTS.AUTH}/password-reset`,
    passwordResetConfirm: `${ROOTS.AUTH}/password-reset-confirm`,
    passwordResetEmailSend: `${ROOTS.AUTH}/password-reset-email-send`,
    passwordResetCompletaion: `${ROOTS.AUTH}/password-reset-completion`,
  },
  emailAuth: {
    emailSend: `${ROOTS.EMAIL_SEND}/verification`,
  },
  dashboard: {
    root: ROOTS.DASHBOARD,
    videoList: `${ROOTS.DASHBOARD}/video-list`,
    videoEdit: (id: string) => `${ROOTS.DASHBOARD}/video-list/${id}/edit`,
    videoChoosePreview: (id: string) =>
      `${ROOTS.DASHBOARD}/video-list/${id}/preview`,
    videoChooseSubtitleEdit: (videoChooseId: string, videoId: string) =>
      `${ROOTS.DASHBOARD}/video-list/${videoChooseId}/edit/subtitle/${videoId}`,
    videoChooseAnnotationEdit: (videoChooseId: string, videoId: string) =>
      `${ROOTS.DASHBOARD}/video-list/${videoChooseId}/edit/annotation/${videoId}`,
    analytics: `${ROOTS.DASHBOARD}/analytics`,
    analyticsVideo: (id: string) => `${ROOTS.DASHBOARD}/analytics/${id}`,
    formDataList: `${ROOTS.DASHBOARD}/form-data`,
    formData: (id: string) => `${ROOTS.DASHBOARD}/form-data/${id}`,
    settings: `${ROOTS.DASHBOARD}/settings`,
  },
  mypage: {
    root: ROOTS.MYPAGE,
    planManagement: `${ROOTS.MYPAGE}/plan-management`,
    userManagement: `${ROOTS.MYPAGE}/user-management`,
  },
  legal: {
    termsOfService: `${ROOTS.LEGAL}/terms-of-service`,
  },
};
